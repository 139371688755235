(function ($) {
  Drupal.behaviors.checkoutFooterMaxPurchaseV1 = {
    attach: function (context) {
      // Defined in Drupal, checkout footer - quick links menu
      var $qlMaxPurchaseOverlay = $('.js-ql-max-purchase-open', context);

      // When Maximum Purchase Policy link is clicked, show overlay.
      $qlMaxPurchaseOverlay.on('click', function () {
        var renderMP = site.template.get({
          name: 'checkout_footer_ql_max_purchase_v1'
        });

        generic.overlay.launch({
          content: renderMP,
          height: 460,
          width: 580
        });
      });
    }
  };
})(jQuery);
